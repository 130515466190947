<template>
  <div class="main-content">
    <section class="page-banner cruises-result">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a class="link">Tarihçe</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">Tarihçe</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-main padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-12 main-right">
                <div class="cruises-list">
                  <div class="row">
                    <div class="col-sm-12 col-xs-12">
                      <div class="cruises-layout">
                        <div class="image-wrapper">
                          <a class="link">
                            <img
                              src="assets/images/tours/tour-3.jpg"
                              alt=""
                              class="img-responsive"
                            />
                          </a>
                        </div>
                        <div class="content-wrapper">
                          <div class="content">
                            <a class="title"> </a>
                            <div class="text">
                              <h1>Ortahisar’ ın Tarihçesi</h1>
                              Ortahisar’ın kuruluşuna dair elimizde yazılı bir
                              belge maalesef mevcut değildir; ancak,
                              Ortahisar’ın erken devirlerden beri yerleşim yeri
                              olarak kullanılmış olduğunu, o devirlere ait çok
                              sayıdaki tarihî kalıntılardan anlıyoruz.
                              Ortahisar’ın dışardan çıkılması imkânsız olan
                              doğal kaleleri, Hititler döneminde ticaret
                              kervanlarına sığınak ve barınak sağlamış önemli
                              yerlerdir. Roma döneminde ise bu sığınaklar, aynı
                              zamanda yerleşim yeri olarak da kullanılmıştır.
                              Roma döneminin ardından, Bizans, Selçuklu ve
                              Osmanlı derken günümüze kadar aynı amaçlarla
                              kullanıla gelmişlerdir. Ortahisar, çevrede
                              özelliği olan bir beldedir. Kasaba çevresinde kaya
                              içerisine oyulmuş çok sayıda yerleşim yeri ve
                              kiliselerin bulunmasıyla bu önemi daha da
                              artmaktadır. <br />
                              Bilhassa (M.S. 53) de Roma İmparatoru olan
                              Diokles’in zulmünden kaçan halkın en çok rağbet
                              edip sığındığı yerler arasında Ortahisar hep öne
                              çıkar. Ortahisar’ı bu denli önemli kılan
                              sebeplerin başında, kuşkusuz halkın can ve mal
                              güvenliği korkusu gelmektedir. Bu korkudan dolayı,
                              can ve mal güvenliğinin en emniyetli sağlandığı
                              yerleri tercih eden halk, üç yanı derin dere
                              vadileri ile çevrili Ortahisar’ı bu özelliğinden
                              de tercih edip, yerleşim yerlerini yoğun olarak
                              buraya yapmışlardır. Burasının tercih edilmesinin
                              bir diğer sebebi de, kasaba çevresinde bulunan tüf
                              kaya katmanlarının burada daha kalın ve daha
                              yüksek oluşudur. Bu tüf kayaların kolay
                              oyulabilmesi de işlerini kolaylaştırmıştır
                              şüphesiz. Tarihin her devrinde önemli bir merkez
                              olduğu, yine o dönemlere ait kalıntılardan
                              anlaşılan Ortahisar, Bizans döneminde de önemini
                              korumuş ve hatta artırmıştır. Bundan dolayıdır ki,
                              Ortahisar’ın çevresindeki arazilere, küçüklü
                              büyüklü manastırlar ve yüzlerce yerleşim yeri inşa
                              etmişlerdir. Selçuklu döneminin en karışık
                              zamanında bile, stratejik konumu sebebiyle önemini
                              korumuş; bir prestij sembolü olmuştur. Zira,
                              Ortahisar’da bulunan kalelere sahip olanın, bütün
                              bölgeye sahip olacağı inancı onu daha fazla değer
                              verilen yer konumuna getirmiştir. <br />
                              Kanımızca, bu stratejik konumundan dolayı da zaman
                              zaman zarar da görmüştür. Bizans’tan sonraki
                              dönemlerde, burasının aidiyetine dair ihtilaflar
                              söz konusu olduğundan, o dönemlere ait kalıcı ve
                              görkemli yapılar buraya maalesef yapılamamıştır.
                              Ortahisar’ın tamamen bir Türk kasabası olması ise,
                              Horasan erenlerinden “Hibe Dede” isminde bir zatın
                              sekiz kardeşi ile birlikte gelip buraya
                              yerleşmesiyle başlar. Kalabalık bir aile olarak
                              buraya yerleşen Hibe Dede ve sülalesi hakkında
                              anlatılan yaygın inanca göre; Hibe Dede ve
                              kardeşleri (1270-1280) Horasan’dan gelip buraya
                              yerleşmişlerdir. Bu kalabalık sülâlenin buraya
                              yerleşmesiyle, nüfus artmaya başlamıştır. Önceleri
                              kısmen “Atlık Dere yatağı” ve kale dibini mesken
                              tutan bu sülâlenin artan nüfusuna yeni meskenler
                              yapılarak, şimdiki Ortahisar kasabası meydana
                              gelmiştir. <br />
                              Yine Hibe Dede hakkında nesilden nesile
                              anlatılarak günümüze kadar gelen inanca göre, bu
                              aileyi “Hoca Ahmet Yesevi”, kendilerine iki önemli
                              misyon yükleyerek buraya göndermiştir. Bu önemli
                              misyonlardan ilki, o dönemde Selçuklu Devleti’nin
                              bu bölgede bozulan düzeni ve aralarındaki kardeş
                              kavgalarının giderilmesine yardımcı olmaktır.
                              Zira, o dönemde Selçuklu’da kardeş kavgaları hat
                              safhaya ulaşmış; özellikle bölgenin ufak
                              beyliklere bölünmesi yüzünden Selçuklu Devleti’ni
                              dağılacak noktaya getirmişti. Diğer misyonu ise,
                              Kapadokya yöresinde yaşayan, dini Hıristiyan olan
                              yerli topluluğu, dinî yönden irşat (aydınlatma)
                              görevidir. İşte bu ulvî görevlerle buraya
                              gönderilmiş bulunan Hibe Dede, Horasan’dan yola
                              birlikte çıktıkları Hacı Bektaş Veli ile bölgeye
                              gelirler. Hacı Bektaş Veli, Suluca Karahöyük
                              köyüne; Hibe Dede de Ortahisar’a yerleşir. Hacı
                              Bektaş Veli, Suluca Karahöyük köyünde
                              yüklendikleri bu dinî ve siyasî misyonunu yerine
                              getirirken, Ortahisar’a yerleşen Hibe Dede de,
                              aynı şekilde halkın içinde, halktan biri olarak
                              üzerine düşen görevi yerine getirmeye çalışmıştır.
                              Kasaba çevresindeki bazı küçük yerli yerleşim
                              birimlerinde yaşayan halkın Hibe Dede sayesinde
                              İslâm dinini kabul etmiş olduğu söylenir. Hibe
                              Dedenin, ayrıca sanatı dericilik olduğundan
                              yerleştikleri bu kasabaya önce iki adet tabakhane
                              (deri işleme yeri) inşa ederek deri işlemeye
                              başlamışlardır. <br> İlk inşa ettikleri tabakhane
                              günümüzde kullanılmıyorsa da sağlam durumdadır.
                              Kapadokya yöresine deri ve deriden mamul giysi
                              üretmeyi Hibe Dede öğretmiştir. Sekiz kardeşten
                              oluşan Hibe Dede sülâlesi, önce buraya
                              yerleşmişlerse de kardeşlerin üçü buradan
                              ayrılmış; Bizans’ın yönetim merkezlerine daha
                              yakın olabilmek arzusuyla olsa gerek başka
                              bölgelere gidip yerleşmişlerdir. Kardeşlerden
                              “Himmet” ve “Torasan”, Kayseri’ye yakın olabilmek
                              için otağlarını oraya yakın yerlere kurmuşlar;
                              kardeşlerden adı Himmet olanın kurduğu otağ
                              günümüzde “Himmetdede Kasabası”dır. Diğer kar -
                              deşlerden Torasan’ın kurduğu otağ ise, günümüzde
                              Başdere (Başköy) adıyla anılmaktadır. Adı “Cibali”
                              olan kardeşlerden diğeri ise o zaman ki
                              Constantinopole yakın olan bir yere gidip
                              yerleşmiş; o zaman yerleştikleri yer de günümüzde
                              İstanbul’un bir semti olarak halen aynı isimle
                              anılmaktadır. <br />
                              <h2>Jeolojik ve Morfolojik Yapı</h2>
                              Ortahisarı Kapadokya yöresinde öne çıkartan
                              niteliklerin temelinde, jeomorfolojik özellikleri
                              yatmaktadır. Bölgenin jeomorfolojisi bölgeye ün
                              kazandıran doğal peyzajı sağlamıştır. Yörenin
                              doğal peyzajı kasabanın tarihî geçmişini ve
                              günümüzdeki gelişme ve yerleşme biçimlerini
                              etkilemiştir. Tarihin çok önceki devirlerinde
                              Erciyes Dağı, Hasan Dağı, Melendiz Dağı ve Göllü
                              Dağ jeolojik devirlerde aktif birer volkandı.
                              Bölgeyi evvela Miosen devrinde bazalttan oluşan
                              lavlar kaplamış. Sonraları Pliosen devrinde,
                              lavlar ve kül yatakları, Erciyes ve Hasan Dağı
                              çevresinde kümelenmiştir. Bu devirlerin sonraları
                              Pleistosen başlarında bazalttan oluşan lavlar, tüf
                              yataklarının bazı bölgesine yayılmışlardır. İşte
                              üzerinde koruyucu bazalt örtüsü bulunan volkanik
                              tüf yatakları böylece meydana gelmiştir. Bu tüfit
                              katmanlarının sertlikleri birbirinden farklıdır;
                              yörede değişken iklim ve ısı farklılıkları,
                              yağmur, kar ve akarsuların tesiri ile farklı
                              sertlikteki tüfitler aşınarak oyulması ile koni
                              şeklinde yükselen kayalar, sütunlar, piramitler ve
                              dikili taşlardan oluşan doğa harikası görüntüler;
                              Ortahisar, Göreme ve Soğanlı gibi dar ve dik
                              kenarlı vadiler oluşmuştur. Resimde de görüleceği
                              gibi bu enteresan şekiller, Ortahisar çevresinde
                              çokça bulunmaktadır. Bunlardan en önemlileri:
                              Tavşanlı Vadisi, Savakağzı Deresi, Devrent Vadisi,
                              Kızılvadi ve Pınarbaşı mevkileridir. Buralarda,
                              çeşitli figürlere bürünmüş kayalıklar çokça
                              görülmektedir. Genel olarak Peri Bacaları olarak
                              adlandırılan bu oluşumlardır.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tarihçe",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style>
p {
  color: black;
}
</style>