<template>
  <div class="main-content">
    <section class="page-banner cruises-result">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a class="link">Kafe & Bistrolar</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">Kafe & Bistrolar</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-main padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-12 main-right">
                <div class="cruises-list">
                  <div class="row">
                    <div
                      class="col-sm-4 col-xs-4"
                      v-for="coffeeShop in coffeeShops"
                      :key="coffeeShop.name"
                    >
                      <div class="cruises-layout">
                        <div class="image-wrapper">
                          <a class="link">
                            <img
                              @click="goDetail(coffeeShop.name)"
                              :src="coffeeShop.resimUrl"
                              alt=""
                              class="img-responsive"
                              style="max-height: 200px; cursor: pointer"
                            />
                          </a>
                        </div>
                        <div class="content-wrapper">
                          <div class="content">
                            <a
                              class="title"
                              @click="goDetail(coffeeShop.name)"
                              style="cursor: pointer"
                            >
                              {{ coffeeShop.title }}
                            </a>
                            <p class="text">
                              {{ coffeeShop.description.slice(0, 150) }}...
                            </p>
                            <button
                              class="btn btn-gray"
                              @click="goDetail(coffeeShop.name)"
                            >
                              Daha Fazla
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import content from "../content.json";
export default {
  name: "CoffeeShops",
  data() {
    return {
      coffeeShops,
    };
  },
  created() {
    content.map((item) => {
      if (item.name == "CoffeeShops") this.coffeeShops = item.data;
    });
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        name: "CafeDetail",
        params: { id },
      });
    },
  },
};
</script>
