<template>
  <div class="main-content">
    <section class="page-banner cruises-detail">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a class="link">Hoteller</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">{{ hotel.name }}</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-detail padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-12 col-xs-12 main-right">
                <img :src="hotel.resimUrl" alt="" class="img-responsive" />
                <div class="wrapper-timeline">
                  <h3 class="title-style-3">Gezi Rehberi</h3>
                  <div class="car-rent-layout">
                    <div class="content-wrapper">
                      <a class="title">{{ hotel.title }}</a>
                      <div class="text" style="max-height: 500px">
                        {{ hotel.description }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  <div class="col-md-4">
                <div class="tours-layout">
                  <div class="image-wrapper">
                    <a class="link">
                      <img
                        :src="hotel.resimUrl"
                        alt=""
                        class="img-responsive"
                      />
                    </a>
                    <div class="title-wrapper">
                      <a class="title">{{ hotel.title }}</a>
                    </div>
                     <div class="label-sale">
                      <p class="text">Popüler</p>
                      <p class="number">Seçim</p>
                    </div> 
                  </div>
                  <div class="content-wrapper">
                    <div class="content">
                      <p class="text-left">
                        {{ hotel.description.slice(0, 400) }}...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import content from "../../content.json";

export default {
  name: "HotelDetail",
  data() {
    return {
      hotels,
      hotel,
    };
  },
  created() {
    content.map((item) => {
      if (item.name == "Hotels") this.hotels = item.data;
    });
    this.hotels.map((hotel) => {
      if (hotel.name == this.$route.params.id) this.hotel = hotel;
    });
  },
};
</script>
