<template>
  <div class="body-wrapper">
    <!-- MENU MOBILE-->
    <div class="wrapper-content">
      <!-- WRAPPER-->
      <div>
        <!-- MAIN CONTENT-->
        <div class="main-content">
          <VideoBanner />
          <section>
            <div class="tab-search tab-search-long tab-search-default">
              <div class="tab-content-bg">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="tab-content">
                        <div role="tabpanel" id="hotel" class="tab-pane active">
                          <div class="find-widget find-hotel-widget widget">
                            <form class="content-widget">
                              <div class="text-input small-margin-top">
                                <iframe
                                  width="100%"
                                  height="400px"
                                  frameborder="0"
                                  allowfullscreen
                                  src="//umap.openstreetmap.fr/tr/map/ortahisar-tourist-map_641818?scaleControl=false&miniMap=false&scrollWheelZoom=false&zoomControl=true&allowEdit=false&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=undefined&captionBar=false"
                                ></iframe>
                                <CircleMaps />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ExploreSect />
        </div>
      </div>
      <!-- FOOTER-->
      <!-- <Footer /> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ExploreSect from "@/components/Home/ExploreSect.vue";
import VideoBanner from "@/components/Home/VideoBanner.vue";
import Footer from "@/components/Footer.vue";
import CircleMaps from "@/components/Home/CircleMaps.vue";

export default {
  name: "Home",

  components: {
    Header,
    ExploreSect,
    VideoBanner,
    Footer,
    CircleMaps,
  },
};
</script>
