<template>
  <div>
    <div class="bg-transparent header-01">
      <Header />
    </div>
    <router-view />
    <!-- BUTTON BACK TO TOP-->
    <Back />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Back from "@/components/BackToTop.vue";

export default {
  components: {
    Footer,
    Header,
    Back,
  },
};
</script>

<style></style>
