<template>
  <div class="main-content">
    <section class="page-banner cruises-result">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a class="link">Mimari</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">Mimari</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-main padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-12 main-right">
                <div class="cruises-list">
                  <div class="row">
                    <div class="col-sm-12 col-xs-12">
                      <div class="cruises-layout">
                        <div class="image-wrapper">
                          <a class="link">
                            <img
                              src="assets/images/tours/tour-3.jpg"
                              alt=""
                              class="img-responsive"
                            />
                          </a>
                        </div>
                        <div class="content-wrapper">
                          <div class="content">
                            <a class="title"> </a>
                            <div class="text">
                              <h1>Ortahisar’ın Eski Mimarisi</h1>
                              <h2>Konut Yapısı</h2>
                              Ortahisar’ın mimarî yapısı, Kapadokya yöresinin
                              genelinde uygulanan mimarî tarzın bir parçasıdır.
                              Yapıların bir kısmı tüf kayalara oyularak
                              yapılmış, oyma esnasında çıkartılan taşlarla
                              yapıların önüne veya üzerine yığma tekniği ile
                              inşa edilen diğer bölümlerden oluşur. Hanenin
                              giriş kapısı, oda pencereleri, genellikle kemerli
                              ve süslü yapılır. Yapının elemanlarının genel yapı
                              içerisinde çevre mimarisine uyumlu olmasına özen
                              gösterilir. Mimarinin Ortahisar’da bu denli bir
                              birine uyumlu olması, halkın çevre ile
                              bütünleştiğinin bariz bir örneğidir. Ortahisar’ı
                              oluşturan konutlarda, zaman içerisinde yapılan
                              ilavelerde devam eden dengeli bir bütünlük dikkati
                              çeker. Bunun nedeni, mimaride, malzeme, teknik ve
                              stil bakımından oldukça etkili bir geleneğin devam
                              ediyor olmasıdır. Aile büyüdükçe haneye ilave
                              yerler yapılır. Bu devamlılık çok eskiden beri
                              uygulana geldiği içindir ki, Ortahisar evlerinin
                              yapıldığı tarihi belirtecek bir yazıt vs.
                              bulunmamaktadır. Bu itibarla evlerin yapıldığı
                              tarihleri tespit etmek güçleşmektedir. Sadece bir
                              merkezi bulunan Ortahisarda, konut alanları ile
                              ticari merkezler iç içedir. Kullanıma göre
                              şekillenen bu yapılarda “eski (klasik) yapılar”,
                              “yeni yapılar” şeklinde iki ayrı tür yapılanmadan
                              bahsedilebilir.
                              <h2>Eski Evlerin Özellikleri</h2>
                              Ortahisar’ın eski evleri iki ve en çok üç katlı
                              prizmatik yapılardan oluşur. Kat sayısı hane
                              halkının nüfusuna bağlı olduğu gibi, evin
                              büyüklüğüne ve arazinin meyline göre de değişir.
                              Hatta meylin fazla olduğu bazı örneklerde zemin
                              kata bir sokaktan girilirken, diğer üst katlara
                              başka sokaktan veya aynı sokağın üst başından
                              girilmektedir. Tüf kaya içerisine oyularak
                              yapılmış bulunan mekânlar, zemin katın bir kısmını
                              oluşturmakta; zeminin bir kısmı ile üst katlar
                              kaya içerisine oyma esnasında elde edilen taşlarla
                              inşa edilmektedir. Haneyi oluşturan avlunun içinde
                              tonozlu bir eyvan şeklinde tandır evi bulunur.
                              Peri bacası şeklindeki kayaların konutlara dâhil
                              edildiği veya konutların yamaca dayandığı bazı
                              örnekler üst katlarda da görülür. Evlerin sokak
                              ile olan ilişkisi avluyla olduğu kadar üst
                              katlarda doğrudan da olabilmektedir. Avlu
                              içerisinde bulunan eyvanlı mekan, etrafındaki
                              şekillenmeyi ve ihtiyaca göre evin büyümesini
                              tayin eder.
                              <h2>Eski Evlerin Bölümleri</h2>
                              Eyvan: isminden de anlaşılacağı gibi, üç yanı
                              kapalı, açık ön kısmı bir kemer bir ara ile
                              sınırlanmış, derinlemesine dikdörtgen planlı
                              tonozlu bir mekândır. Kasaba halkı bu yerlere
                              “Kemer Altı” adını verir. Eyvan tek olduğu zaman
                              tandır bu eyvanın altında yer almakta, çoklu eyvan
                              olduğunda bunlardan birisi “tandır evi”görevi
                              görmektedir. Tandır Evi: Halkın “Kemer Altı” da
                              dediği tonozlu eyvan, tandır evi Ortahisar
                              evlerinin en önemli birimidir. Ekmek pişirilen
                              tandır bu mekanın ortasında bulunur. Tandır 40-50
                              cm çapında ve yaklaşık 50-60 cm derinliğinde
                              Avanos’un kırmızı testi toprağından karılan
                              çamurla yapılır. Tamamen kendi halinde kuruduktan
                              sonra tandır evindeki onun için boyunca oyulmuş
                              çukura yerleştirilir. Isı kaybını asgariye
                              indirmek için etrafına çekilmemiş kaba tuz, kül,
                              kiremit kırıkları vs. ile doldurulur. Kademeli
                              artan ısıda (içinde yakılan odunla) birkaç gün
                              pişmesi sağlanır. Tandırın etrafı yayvan taşlarla
                              çevrilir; kullanıldığı zaman üzeri yine yayvan ve
                              yuvarlak bir taş kapakla örtülür. Mutfak: Tandır
                              evinin gerisinde ön kısmı mutfak, arkası erzak
                              deposu (Kayıt Damı) olarak kullanılan bazen tek
                              bazen de iki kısımlı kayaya oyma mekânlar yer
                              alır. Tandırda ekmek pişirildikten sonra çömlek
                              içerisinde kurutulmuş etli kemikler ile pişirilen
                              Kuru fasulye (Ağ Pakla), içerisi nohutlu bulgur
                              çorbası, çömlekte pişirilmiş Tokalı oğlu
                              kayısısından tatlı yahni ve az haşlanarak ev
                              sirkesi ile kurulmuş biber turşusu halkın en fazla
                              tercih edilen yemek çeşitleri idi. Erzak Odası
                              (Kayıt Damı): Tandır evinin arkasında bulunan oyma
                              mekânlara “Kayıt Damı” adı verilir. Bu yerlerde
                              geniş ve ailenin büyüklüğüne göre küçük, orta veya
                              büyük yapılmış bir un sandığı bulunur. Her çeşit
                              bakliyatların saklandığı irili ufaklı küpler, kış
                              yaz yenilecek şekilde kavrulup teker halinde
                              dökülerek dondurulmuş et ve kıymalar (Sılgıç)
                              bulunurdu. Zemin Kat Odaları: Sayısı 1-3 arasında
                              değişen zemin kat odaları çoğunlukla eyvanın
                              etrafında guruplaşır. Bu dikdörtgen ve tonozla
                              örtülü odaların döşemeleri taştır. Bu odaların
                              bazılarında küçük tandır veya şömine bulunur,
                              böyle olanlara “Kış Evi” denir. Sedir bulunmasına
                              rağmen raf, niş, dolap, seki gibi üst kat
                              odalarında görülen unsurlara burada pek
                              rastlanmaz. Aynı karakter ve yapıda olan odaların
                              bazen oda, bazen depo, bazen de ahır olarak
                              kullanıldığı olur. Çoğunlukla yığma teknikle inşa
                              edilmiş olan odaların yanı sıra, oyma mekânların
                              da oda olarak kullanıldığı olur. Kiler-Depolar:
                              Halkın ancak kendine yetecek kadar yetiştirdiği
                              kuru üzüm, kayısı, ayva, elma, armut, soğan ve
                              patates gibi ürünler zemin katta bulunan oyma
                              mekânlarda saklanır. Bu depoların sayısı ve
                              boyutları farklıdır. Her evde mutlaka bir depo
                              bulunur. Bu, kaya oyma mekânların çoğu önceden
                              hazırlanır evlerin yığma kısımları daha sonra
                              bunların üzerine veya önüne inşa edilir. Salkım
                              halinde çalıya bağlanarak asılı üzümler, kışın
                              hayvanlara yem olması için kurutulan ağaç ve asma
                              yaprakları (Gazel), saman ve yem yine bu yerlerde
                              saklanır. Oda ögeleri: Odalarda; pabuçluk,
                              musandıra, seki, dolap, yüklük, sedir, lambalık,
                              taka, aynalık, raf, kapalı veya açık “Çağ” denilen
                              banyo ve gusülhane gibi birimler bulunur.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tarihçe",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style>
p {
  color: black;
}
</style>