<template>
  <div class="main-content">
    <section class="page-banner cruises-detail">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a  class="link">gezi detay</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">gezi detay</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-detail padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-8 col-xs-12 main-right">
                <img
                  src="assets/images/tours/tour-1.jpg"
                  alt=""
                  class="img-responsive"
                />

                <div class="wrapper-timeline">
                  <h3 class="title-style-3">Gezi Rehberi</h3>
                  <div class="car-rent-layout">
                    <div class="content-wrapper">
                      <a  class="title">{{ trekkingRoute.title }}</a>

                      <div class="text" style="max-height:500px">
                        {{ trekkingRoute.description }}
                      </div>
                    </div>
                  </div>

                  <!--    <div class="timeline-container">
                    <div class="timeline">
                      <div class="timeline-block">
                        <div class="timeline-title">
                          <span>Day 01</span>
                        </div>
                        <div class="timeline-content medium-margin-top">
                          <div class="row">
                            <div class="timeline-point">
                              <i class="fa fa-circle-o"></i>
                            </div>
                            <div class="timeline-custom-col content-col">
                              <div class="timeline-location-block">
                                <p class="location-name">
                                  Ortahisar Castles
                                  <i class="fa fa-map-marker icon-marker"></i>
                                </p>
                                <p class="description">
                                  Lorem ipsum dolor sit amet, consectetur. Nulla
                                  rhoncus ultrices purus, volutpat. Lorem ipsum
                                  dolor sit amet, consectetur elit dolor sit
                                  amet, consectetur nulla rhoncus ultrices
                                  purus. Lorem ipsum dolor sit amet, consectetur
                                  elit dolor sit amet. Lorem ipsum dolor sit
                                  amet, consectetur elit dolor sit amet,
                                  consectetur nulla rhoncus ultrices purus.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline-block">
                        <div class="timeline-title">
                          <span>Day 02</span>
                        </div>
                        <div class="timeline-content medium-margin-top">
                          <div class="row">
                            <div class="timeline-point">
                              <i class="fa fa-circle-o"></i>
                            </div>
                            <div class="timeline-custom-col content-col">
                              <div class="timeline-location-block">
                                <p class="location-name">
                                  Ortahisar Chuches
                                  <i class="fa fa-map-marker icon-marker"></i>
                                </p>
                                <p class="description">
                                  Lorem ipsum dolor sit amet, consectetur. Nulla
                                  rhoncus ultrices purus, volutpat. Lorem ipsum
                                  dolor sit amet, consectetur elit dolor sit
                                  amet, consectetur nulla rhoncus ultrices
                                  purus. Lorem ipsum dolor sit amet, consectetur
                                  elit dolor sit amet. Lorem ipsum dolor sit
                                  amet, consectetur elit dolor sit amet,
                                  consectetur nulla rhoncus ultrices purus.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="timeline-block">
                        <div class="timeline-title">
                          <span>Day 03</span>
                        </div>
                        <div class="timeline-content medium-margin-top">
                          <div class="row">
                            <div class="timeline-point">
                              <i class="fa fa-circle-o"></i>
                            </div>
                            <div class="timeline-custom-col content-col">
                              <div class="timeline-location-block">
                                <p class="location-name">
                                  Water Springs
                                  <i class="fa fa-map-marker icon-marker"></i>
                                </p>
                                <p class="description">
                                  Lorem ipsum dolor sit amet, consectetur. Nulla
                                  rhoncus ultrices purus, volutpat. Lorem ipsum
                                  dolor sit amet, consectetur elit dolor sit
                                  amet, consectetur nulla rhoncus ultrices
                                  purus. Lorem ipsum dolor sit amet, consectetur
                                  elit dolor sit amet. Lorem ipsum dolor sit
                                  amet, consectetur elit dolor sit amet,
                                  consectetur nulla rhoncus ultrices purus.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="timeline-book-block book-tour">
                      <div
                        class="find-widget find-hotel-widget widget new-style"
                      >
                        <h4 class="title-widgets">BOOK ROOM</h4>
                        <form class="content-widget">
                          <div class="text-input small-margin-top">
                            <div class="input-daterange">
                              <div class="text-box-wrapper half">
                                <label class="tb-label">Check in</label>
                                <div class="input-group">
                                  <input
                                    type="text"
                                    placeholder="MM/DD/YY"
                                    class="tb-input"
                                  />
                                  <i
                                    class="
                                      tb-icon
                                      fa fa-calendar
                                      input-group-addon
                                    "
                                  ></i>
                                </div>
                              </div>
                              <div class="text-box-wrapper half">
                                <label class="tb-label">Check out</label>
                                <div class="input-group">
                                  <input
                                    type="text"
                                    placeholder="MM/DD/YY"
                                    class="tb-input"
                                  />
                                  <i
                                    class="
                                      tb-icon
                                      fa fa-calendar
                                      input-group-addon
                                    "
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div class="count adult-count text-box-wrapper">
                              <label class="tb-label">Adult</label>
                              <div class="select-wrapper">
                            
                                <select
                                  class="form-control custom-select selectbox"
                                >
                                  <option selected="selected">1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                  <option>7</option>
                                  <option>8</option>
                                  <option>9</option>
                                </select>
                              </div>
                            </div>
                            <div class="count child-count text-box-wrapper">
                              <label class="tb-label">Child</label>
                              <div class="select-wrapper">
                                
                                <select
                                  class="form-control custom-select selectbox"
                                >
                                  <option selected="selected">0</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                  <option>7</option>
                                  <option>8</option>
                                  <option>9</option>
                                </select>
                              </div>
                            </div>
                            <div class="first-name text-box-wrapper">
                              <label class="tb-label">Your First Name</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Write your first name"
                                  class="tb-input"
                                />
                              </div>
                            </div>
                            <div class="last-name text-box-wrapper">
                              <label class="tb-label">Your Last Name</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Write your last name"
                                  class="tb-input"
                                />
                              </div>
                            </div>
                            <div class="email text-box-wrapper">
                              <label class="tb-label">Your Email</label>
                              <div class="input-group">
                                <input
                                  type="email"
                                  placeholder="Write your email address"
                                  class="tb-input"
                                />
                              </div>
                            </div>
                            <div class="phone text-box-wrapper">
                              <label class="tb-label">Your Number Phone</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Write your number phone"
                                  class="tb-input"
                                />
                              </div>
                            </div>
                            <div class="place text-box-wrapper">
                              <label class="tb-label"
                                >Where are your address?</label
                              >
                              <div class="input-group">
                                <input
                                  type="text"
                                  placeholder="Write your address"
                                  class="tb-input"
                                />
                              </div>
                            </div>
                            <div class="note text-box-wrapper">
                              <label class="tb-label">Note:</label>
                              <div class="input-group">
                                <textarea
                                  placeholder="Write your note"
                                  rows="3"
                                  name="content"
                                  class="tb-input"
                                ></textarea>
                              </div>
                            </div>
                            <button
                              type="submit"
                              data-hover="SEND REQUEST"
                              class="btn btn-slide"
                            >
                              <span class="text">BOOK Now</span>
                              <span class="icons fa fa-long-arrow-right"></span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>-->
                </div>
              </div>
              <div class="col-md-4">
                <div class="tours-layout">
                  <div class="image-wrapper">
                    <a class="link">
                      <img
                        src="assets/images/tours/tour-2.jpg"
                        alt=""
                        class="img-responsive"
                      />
                    </a>
                    <div class="title-wrapper">
                      <a class="title">tour ın 3 day</a>
                    </div>
                    <div class="label-sale">
                      <p class="text">best</p>
                      <p class="number">choıce</p>
                    </div>
                  </div>
                  <div class="content-wrapper">
                    <div class="content">
                      <p class="text">
                        Lorem ipsum dolor sit amet, consectetur elit. Nulla
                        rhoncus ultrices purus, volutpat. Lorem ipsum dolor sit
                        amet, consectetur elit. Nulla rhoncus ultrices purus,
                        volutpat. Lorem ipsum dolor sit amet, consectetur elit.
                        Nulla rhoncus ultrices purus, volutpat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import content from "../../content.json";

export default {
  name: "RedValley",
  data() {
    return {
      trekkingRoutes,
      trekkingRoute,
    };
  },
  created() {
    content.map((item) => {
      if (item.name == "TrekkingRoutes") this.trekkingRoutes = item.data;
    });
    this.trekkingRoutes.map((trekkingRoute) => {
      if (trekkingRoute.name == "red-valley")
        this.trekkingRoute = trekkingRoute;
    });
  },
};
</script>
