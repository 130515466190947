<template>
  <div class="main-content">
    <section class="page-banner cruises-result">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a class="link">Restoranlar</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">Restoranlar</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-main padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-12 main-right">
                <div class="cruises-list">
                  <div class="row">
                    <div
                      class="col-sm-4 col-xs-4"
                      v-for="restaurant in restaurants"
                      :key="restaurant.id"
                    >
                      <div class="cruises-layout">
                        <div class="image-wrapper">
                          <a class="link" @click="goDetail(restaurant.name)">
                            <img
                              :src="restaurant.resimUrl"
                              alt=""
                              class="img-responsive"
                              style="max-height: 200px; cursor: pointer"
                            />
                          </a>
                        </div>
                        <div class="content-wrapper">
                          <div class="content">
                            <a
                              class="title"
                              @click="goDetail(restaurant.name)"
                              style="cursor: pointer"
                            >
                              {{ restaurant.title }}
                            </a>
                            <p class="text">
                              {{ restaurant.description.slice(0, 150) }}...
                            </p>
                            <button
                              class="btn btn-gray"
                              @click="goDetail(restaurant.name)"
                            >
                              Daha Fazla
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import content from "../content.json";
export default {
  name: "Restaurants",
  data() {
    return {
      restaurants,
    };
  },
  created() {
    content.map((item) => {
      if (item.name == "Restaurants") this.restaurants = item.data;
    });
    console.log("burası restoranlar");
    console.log(this.restaurants);
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        name: "RestaurantDetail",
        params: { id },
      });
    },
  },
};
</script>
