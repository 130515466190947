<template>
  <div class="body-wrapper">
    <!-- MENU MOBILE-->
    <MobileMenu />
    <!-- WRAPPER CONTENT-->
    <div class="wrapper-content">
      <!-- HEADER-->
      <!-- <header>
        <div class="bg-transparent header-01">
          <Header />
        </div>
      </header> -->
      <!-- WRAPPER-->
      <div>
        <!-- MAIN CONTENT-->
        <ContentDetail />

        <!-- BUTTON BACK TO TOP-->
        <BackToTop />
      </div>
      <!-- FOOTER-->
      <!-- <Footer /> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import BackToTop from "@/components/BackToTop.vue";
import ContentDetail from "@/components/Content/ContentDetail.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    MobileMenu,
    BackToTop,
    ContentDetail,
    Footer,
  },
};
</script>