<template>
  <div class="main-content">
    <section class="page-banner cruises-result">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a class="link">Yerel Kültür</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">Giyim</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-main padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-12 main-right">
                <div class="cruises-list">
                  <div class="row">
                    <div class="col-sm-12 col-xs-12">
                      <div class="cruises-layout">
                        <div class="image-wrapper">
                          <a class="link">
                            <img
                              src="assets/images/tours/tour-3.jpg"
                              alt=""
                              class="img-responsive"
                            />
                          </a>
                        </div>
                        <div class="content-wrapper">
                          <div class="content">
                            <a class="title"> </a>
                            <div class="text">
                              <h1>Ortahisar’ ın kültürü</h1>
                              Ortahisar’ın kültürü, Türkiye genelinde uygulanan
                              kültürün bir parçasıdır. Ancak, kendine özgü bazı
                              küçük farklılıklar da yok değildir. Bu
                              farklılıkların başında komşuluk münasebetleri
                              gelir. Kasaba halkının komşuları ile muhabbetleri
                              ailevi münasebetlerden farksızdır. Darda kalan bir
                              komşunun yardımına koşarak sıkıntısı giderilmeye
                              çalışılır. İyi ve dürüst komşu devamlı el üstünde
                              tutulur. Kötü komşu ile dayanışma en aza
                              indirilirse de, hiç bir zaman tamamen dışlanmaz.
                              “Ev alma komşu al.” atasözü ve dinimizde ‘Komşuyu
                              komşuya mirasçı kılardım.’ hadisi şerifi, yaygınca
                              söylenen ve tatbik edilen bir uygulamadır.
                              <h2>Mahalli Kıyafetler</h2>
                              Ortahisar’da eskiden daha çok giyilen giysiler,
                              günümüzde artık giyilmiyor. Eski giysilerin
                              isimlerini bilen bile giderek azalıyor kasabada...
                              Üç peşli, salta, çuha, bindallı, şalvar, cepken,
                              entari, kıstırma, sırmalı kadife elbise, ceket,
                              etek, fes, siyeç, lapçın, şiptirik, el örgüsü
                              süslü çorap, yemeni-yazma ve çarşaf gibi giysiler
                              tamamen yok olmuş durumdadır. Gerek rahatlık,
                              gerekse ananevi adet gereği giyilen bu kıyafetler
                              önem taşırdı. Kadınlar, şalvar ve yemeniye çok
                              önem verirler; bu önemin nişanesi olarak hiç
                              olmayanın yedi-sekiz şalvarı, yirmi-yirmi beş
                              oyalı yemenisi bulunurdu sandığında… Kadınlar,
                              başlarına bağladıkları yemeni yazmaların
                              kenarlarını süslerler; mekik, tığ ve iğne ile
                              yapılan süslü oyalara çeşitli ilginç isimler
                              verirlerdi.
                              <h2>İğne ile yapılan oyalar</h2>
                              Elti eltiye küstü, yelpazeli, yel değirmeni,
                              kuşdili, maydanozlu, saray süpürgesi, dutlu, gelin
                              göbeği, menekşeli, motifli, yıldızlı, küpeli,
                              karanfilli, çarkıfelek, çilekli, muzlu, kelebekli,
                              bademli, Süreyya’nın gözyaşları…
                              <h2>Tığ ile yapılan oyalar</h2>
                              Türkan Şoray’ın kipriği, beş güllü, mezar taşı
                              cennet gülü, armutlu, üç güllü, yazıman, loğusa
                              yatağı, tek mekik, keloğlan, maydanozlu, dolma
                              biber, aşık yolu şaşırdı, kız kaçıran vs. dir.
                              <h2>Mekik ile yapılan oyalar</h2>
                              Subay sırması, yılan kemiği, mercimekli, Ecevit’in
                              burnu, kalpli, gelin tacı, topal asker, zayıf
                              asker, sümüklüböcek, hayat ağacım, ojeli tırnak,
                              kaynana dili, sıçandişi, kuşdili vb. gibi çeşitler
                              vardır.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tarihçe",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style>
p {
  color: black;
}
h2 {
  padding-top: 10px;
}
</style>