<template>
  <div class="main-content">
    <section class="page-banner cruises-result">
      <div class="container">
        <div class="page-title-wrapper">
          <div class="page-title-content">
            <ol class="breadcrumb">
              <li>
                <a href="/" class="link home">Anasayfa</a>
              </li>
              <li class="active">
                <a class="link">Yerel Kültür</a>
              </li>
            </ol>
            <div class="clearfix"></div>
            <h2 class="captions">Halıcılık</h2>
          </div>
        </div>
      </div>
    </section>
    <div class="page-main">
      <div class="trip-info">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="label-route-widget">
                <i class="fa fa-long-arrow-right"></i>
                <span class="departure">
                  <span class="city">Ortahisar, </span>
                  <span class="country">Nevşehir</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="cruises-result-main padding-top padding-bottom">
        <div class="container">
          <div class="result-body">
            <div class="row">
              <div class="col-md-12 main-right">
                <div class="cruises-list">
                  <div class="row">
                    <div class="col-sm-12 col-xs-12">
                      <div class="cruises-layout">
                        <div class="image-wrapper">
                          <a class="link">
                            <img
                              src="assets/images/tours/tour-3.jpg"
                              alt=""
                              class="img-responsive"
                            />
                          </a>
                        </div>
                        <div class="content-wrapper">
                          <div class="content">
                            <a class="title"> </a>
                            <div class="text">
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "halicilik",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style>
p {
  color: black;
}
h2 {
  padding-top: 10px;
}
</style>