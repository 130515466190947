import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Castles from "../views/Castles.vue";
import CastleDetail from "../components/Content/CastleDetail.vue";
import Mosks from "../views/Mosks.vue";
import MoskDetail from "../components/Content/MoskDetail.vue";
import Churches from "../views/Churches.vue";
import ChurchDetail from "../components/Content/ChurchDetail.vue";
import WaterSprings from "../views/WaterSprings.vue";
import WaterSpringDetail from "../components/Content/WaterSpringDetail.vue";
import FairyChimneys from "../views/FairyChimneys.vue";
import FairyChimneyDetail from "../components/Content/FairyChimneyDetail.vue";
import PanoramicViewPoints from "../views/PanoramicViewPoints.vue";
import PanoramicViewPointDetail from "../components/Content/PanoramicViewPointDetail.vue";
import BalkanValley from "../components/Content/BalkanValley.vue";
import OrtahisarValley from "../components/Content/OrtahisarValley.vue";
import UzengiValley from "../components/Content/UzengiValley.vue";
import ZemiValley from "../components/Content/ZemiValley.vue";
import RedValley from "../components/Content/RedValley.vue";
import Restaurants from "../views/Restaurants.vue";
import Hotels from "../views/Hotels.vue";
import CoffeeShops from "../views/CoffeeShops.vue";
import Shopping from "../views/Shopping.vue";
import TravelAgencies from "../views/TravelAgencies.vue";
import TaxiDolmus from "../views/TaxiDolmus.vue";
import Library from "../views/Library.vue";
import ChangeMoney from "../views/ChangeMoney.vue";
import InformationPoint from "../views/InformationPoint.vue";
import PlanningTrip from "../views/PlanningTrip.vue";
import PopulerPlaces from "../views/ContentDetail.vue";
import RestaurantDetail from "../components/Content/RestaurantDetail.vue";
import HotelDetail from "../components/Content/HotelDetail.vue";
import CafeDetail from "../components/Content/CafeDetail.vue";
import ShoppingDetail from "../components/Content/ShoppingDetail.vue";
import TravelDetail from "../components/Content/TravelAgenciesDetail.vue";
import Tarihce from "../views/tarihce.vue";
import Mimari from "../views/mimari.vue";
import Giyim from "../views/giyim.vue";
import Halicilik from "../views/halicilik.vue";
import Onyx from "../views/onyx.vue";
import Bagcilik from "../views/Bagcilik.vue";


Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
  }, {
    path: "/gezi-detay/:id",
    name: "TravelDetail",
    component: TravelDetail,
  },
  {
    path: "/hotel-detay/:id",
    name: "HotelDetail",
    component: HotelDetail,
  }, {
    path: "/alisveris-detay/:id",
    name: "ShoppingDetail",
    component: ShoppingDetail,
  }, {
    path: "/kafe-detay/:id",
    name: "CafeDetail",
    component: CafeDetail,
  }, {
    path: "/restoran-detay/:id",
    name: "RestaurantDetail",
    component: RestaurantDetail,
  },
  {
    path: "/contentdetail",
    name: "PopulerPlaces",
    component: PopulerPlaces,
  },
  {
    path: "/kaleler",
    name: "Castles",
    component: Castles,
  },
  {
    path: "/kale-detay/:id",
    name: "CastleDetail",
    component: CastleDetail,
  },
  {
    path: "/camiler",
    name: "Mosks",
    component: Mosks,
  },
  {
    path: "/cami-detay/:id",
    name: "MoskDetail",
    component: MoskDetail,
  },
  {
    path: "/kiliseler",
    name: "Churches",
    component: Churches,
  },
  {
    path: "/kilise-detay/:id",
    name: "ChurchDetail",
    component: ChurchDetail,
  },
  {
    path: "/su-kaynaklari",
    name: "WaterSprings",
    component: WaterSprings,
  },
  {
    path: "/su-kaynagi-detay/:id",
    name: "WaterSpringDetail",
    component: WaterSpringDetail,
  },
  {
    path: "/peri-bacalari",
    name: "FairyChimneys",
    component: FairyChimneys,
  },
  {
    path: "/peri-bacasi-detay/:id",
    name: "FairyChimneyDetail",
    component: FairyChimneyDetail,
  },
  {
    path: "/seyir-tepeleri",
    name: "PanoramicViewPoints",
    component: PanoramicViewPoints,
  },
  {
    path: "/seyir-tepesi-detay/:id",
    name: "PanoramicViewPointDetail",
    component: PanoramicViewPointDetail,
  },
  {
    path: "/peri-bacalari",
    name: "FairyChimneys",
    component: FairyChimneys,
  },
  {
    path: "/balkandere-vadisi",
    name: "BalkanValley",
    component: BalkanValley,
  },
  {
    path: "/ortahisar-vadisi",
    name: "OrtahisarValley",
    component: OrtahisarValley,
  },
  {
    path: "/uzengi-vadisi",
    name: "UzengiValley",
    component: UzengiValley,
  },
  {
    path: "/zemi-vadisi",
    name: "ZemiValley",
    component: ZemiValley,
  },
  // {
  //   path: "/red-valley",
  //   name: "RedValley",
  //   component: RedValley,
  // },
  {
    path: "/restoranlar",
    name: "Restaurants",
    component: Restaurants,
  },
  {
    path: "/hoteller",
    name: "Hotels",
    component: Hotels,
  },
  {
    path: "/kafe-bistrolar",
    name: "CoffeeShops",
    component: CoffeeShops,
  },
  {
    path: "/alisveris",
    name: "Shopping",
    component: Shopping,
  },
  {
    path: "/seyahat-acenteleri",
    name: "TravelAgencies",
    component: TravelAgencies,
  },
  {
    path: "/taksi-ve-dolmus",
    name: "TaxiDolmus",
    component: TaxiDolmus,
  },
  {
    path: "/kutuphane",
    name: "Library",
    component: Library,
  },
  {
    path: "/doviz-burosu",
    name: "ChangeMoney",
    component: ChangeMoney,
  },
  {
    path: "/bilgi-noktaları",
    name: "InformationPoint",
    component: InformationPoint,
  },
  {
    path: "/seyahatini-planla",
    name: "PlanningTrip",
    component: PlanningTrip,
  },
  {
    path: "/tarihce",
    name: "Tarihçe",
    component: Tarihce,
  },
  {
    path: "/mimari",
    name: "Mimari",
    component: Mimari,
  },
  {
    path: "/giyim",
    name: "giyim",
    component: Giyim,
  },
  {
    path: "/halicilik",
    name: "halicilik",
    component: Halicilik,
  },
  {
    path: "/onyx-isletmeciligi",
    name: "onyx-isletmeciligi",
    component: Onyx,
  },
  {
    path: "/bagcilik-ve-tarim",
    name: "bagcilik-ve-tarim",
    component: Bagcilik,
  },
  {
    path: "/iletisim",
    name: "Contact",
    component: Contact,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;